import React from "react"
import ChooseJourney from "../components/journeys"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <ChooseJourney>
      Oh no! The page you're looking for can't be found. <br />
      Try one of these:
    </ChooseJourney>
  </Layout>
)

export default NotFoundPage
